<template>
    <modal ref="modalDetalle" titulo="Importacion anulada" tamano="modal-lg" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col-12 mb-3 text-general f-16 f-600">
                Información de la importación
            </div>
        </div>
        <div class="row mx-0 bg-light-f5 py-2">
            <div class="col-4 d-flex">
                <i class="icon-ok-circled text-success f-18" />
                <div class="col pl-2 text-general">
                    <p class="f-16 f-500">{{ datos.productos_numero }}</p>
                    <p class="f-14"> <i>Productos importados</i> </p>
                </div>
            </div>
            <div class="col-4 d-flex">
                <i class="icon-comision text-general f-17" />
                <div class="col pl-2 text-general">
                    <p class="f-16 f-500">{{ datos.productos_valor }}</p>
                    <p class="f-14"> <i>Inventario Importado</i> </p>
                </div>
            </div>
            <div class="col-4 d-flex">
                <i class="icon-attention-alt text-general-red f-18" />
                <div class="col pl-2 text-general">
                    <p class="f-16 f-500">{{ datos.productos_invalidos }}</p>
                    <p class="f-14"> <i>Productos invalidos</i> </p>
                </div>
            </div>
            <div class="col-4 d-flex mt-3">
                <i class="icon-account-check text-general f-20" />
                <div class="col pl-2 pt-1 text-general">
                    <p class="f-16 f-500">{{ datos.responsable }}</p>
                    <p class="f-14"> <i>Responsable</i> </p>
                </div>
            </div>
            <div class="col-4 d-flex mt-3">
                <i class="icon-fecha-fin text-general f-22" />
                <div class="col pl-2 text-general pt-1">
                    <p class="f-16 f-500">{{ datos.created_at | helper-fecha('DD MMM Y') }}</p>
                    <p class="f-14"> <i>Fecha de actualización</i> </p>
                </div>
            </div>
        </div>
        <div class="row mx-0 py-3" />
        <div class="row mx-0 mt-4">
            <div class="col-12 mb-3 text-general f-16 f-600">
                Información de la anulación
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-4 d-flex">
                <i class="icon-account-check text-general f-18" />
                <div class="col pl-2">
                    <p class="text-general f-16 f-600">{{ datos.anulador }}</p>
                    <p class="text-general f-14"> <i>Anulado</i> </p>
                </div>
            </div>
            <div class="col-4 d-flex">
                <i class="icon-fecha-fin text-general f-22" />
                <div class="col pl-2">
                    <p class="text-general f-16 f-600">{{ datos.updated_at | helper-fecha('DD MMM Y') }}</p>
                    <p class="text-general f-14"> <i>Fecha de anulación</i> </p>
                </div>
            </div>
            <div class="col-12 mt-3 d-flex">
                <i class="icon-card-text-outline text-general f-20" />
                <div class="col pl-2 pt-1">
                    <p class="text-general f-16 f-600">Justificación</p>
                    <p class="text-general f-15">
                        <i> {{ datos.anulado_comentario }} </i>
                    </p>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return {
            datos:{}
        }
    },
    computed:{
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        },
        cedis(){
            if(this.cedis_calculo.length == 0){
                return {}
            }
            return this.cedis_calculo.find(o=>o.id == this.id_cedis)
        }
    },
    methods: {
        toggle(item){
            this.datos = item
            this.$refs.modalDetalle.toggle();
        }
    }
}
</script>
